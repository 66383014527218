import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-polyglot';
import { graphql } from 'gatsby';

import withSetLocale from '../../components/i18n/withSetLocale';

import Layout from '../../components/layout';
import ShowcasesPage from '../../components/ShowcasesPage';

const Showcases = ({ data, pageContext: { locale, messages } }) => (
    <Layout>
        <I18n locale={locale} messages={messages}>
            <ShowcasesPage locale={locale} data={data} />
        </I18n>
    </Layout>
);

Showcases.propTypes = {
    data: PropTypes.object.isRequired,
    pageContext: PropTypes.object.isRequired,
};

export default withSetLocale()(Showcases);

export const pageQuery = graphql`
    query ShowCasesQuery {
        headerImage: file(relativePath: { eq: "showcases/showcase-bg.jpg" }) {
            publicURL
            absolutePath
            name
            childImageSharp {
                fluid(maxWidth: 1280, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }

        showcaseImages: allFile(
            filter: {
                sourceInstanceName: { eq: "images" }
                absolutePath: { glob: "**/showcases/**" }
            }
        ) {
            edges {
                node {
                    publicURL
                    absolutePath
                    name
                    childImageSharp {
                        fluid(maxWidth: 608) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
        }

        customers: allFile(
            sort: { fields: absolutePath }
            filter: {
                sourceInstanceName: { eq: "images" }
                absolutePath: { glob: "**/customers/**" }
            }
        ) {
            edges {
                node {
                    publicURL
                    name
                    childImageSharp {
                        fluid(maxWidth: 200, quality: 100) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
        }
        showcases: allMdx(
            sort: { order: DESC, fields: [frontmatter___order] }
            filter: { fileRelativePath: { glob: "**/showcases/**" } }
        ) {
            edges {
                node {
                    id
                    frontmatter {
                        customer
                        title
                        text
                        image
                        project
                        lang
                        link
                    }
                }
            }
        }
        repositories: allGithubData {
            nodes {
                data {
                    francois {
                        repositories {
                            nodes {
                                name
                                stargazers {
                                    totalCount
                                }
                                url
                            }
                        }
                    }
                    marmelab {
                        repositories {
                            nodes {
                                name
                                stargazers {
                                    totalCount
                                }
                                url
                            }
                        }
                    }
                }
            }
        }
    }
`;
