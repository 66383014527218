import React from 'react';
import PropTypes from 'prop-types';

import Image from 'gatsby-image';

const ProgressiveImage = ({ src, style }) => {
    if (!src.childImageSharp) {
        return <img src={src} />;
    }
    if (src.childImageSharp.fluid) {
        return <Image fluid={src.childImageSharp.fluid} style={style} />;
    }
    if (src.childImageSharp.fixed) {
        return <Image fixed={src.childImageSharp.fixed} style={style} />;
    }
};

ProgressiveImage.propTypes = {
    src: PropTypes.object,
    style: PropTypes.object,
};

export default ProgressiveImage;
