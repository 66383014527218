import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FaArrowRight as IconArrowRight } from 'react-icons/fa';

import { LinkButton } from './Button';

const StyledLinkButton = styled(LinkButton)`
    justify-content: ${({ centered }) => (centered ? 'center' : 'normal')};
    &:hover svg,
    &:active svg {
        margin-left: 1.5rem;
    }
`;

const StyledIconArrowRight = styled(IconArrowRight)`
    transition: margin-left 0.3s ease-out;
    font-size: 1.5rem;
    color: ${({ color }) => color};
    margin-left: 0.5rem;
    position: relative;
    top: -0.1rem;
`;

const ViewMore = ({ color, label, href, centered = false }) => (
    <StyledLinkButton href={href} color={color} centered={centered}>
        <span>{label}</span>{' '}
        <StyledIconArrowRight className="arrow-right" color={color} />
    </StyledLinkButton>
);

ViewMore.propTypes = {
    label: PropTypes.string.isRequired,
    href: PropTypes.string,
    color: PropTypes.string.isRequired,
    centered: PropTypes.bool,
};

export default ViewMore;
