import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { translate } from 'react-polyglot';
import compose from 'recompose/compose';
import mapProps from 'recompose/mapProps';
import LazyLoad from 'react-lazyload';
import Helmet from 'react-helmet';

import { PrimaryLink } from './Button';
import AppBar from './AppBar';
import CallToAction from './CallToAction';
import Customers from './Customers';
import BigShowcase from './Showcases/BigShowcase';
import Footer from './Footer';
import OpensourceProjects from './Showcases/OpensourceProjects';
import MaxWidthContainer from './MaxWidthContainer';
import BaseHeaderImage from './HeaderImage';
import TopBar from './TopBar';
import { GithubLink } from './IconLink';

const Container = styled.div`
    background-color: ${({ theme }) => theme.gray}
    display: flex;
    flex-direction: column;
`;

const HeaderImage = styled(BaseHeaderImage)`
    > div:first-child {
        background: linear-gradient(
            180deg,
            rgba(0, 0, 0, 0.5) 0%,
            rgba(0, 0, 0, 0) 100%
        );
    }
`;

const Intro = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 8rem 0;

    @media (max-width: 600px) {
        margin: 4rem 0;
    }
`;
const Title = styled.h1`
    color: ${({ theme }) => theme.white};
    font-family: 'Montserrat', sans-serif;
    font-size: 2.5rem;
    font-weight: 300;
    line-height: 3rem;
    text-align: center;

    @media (max-width: 600px) {
        font-size: 1.75rem;
        line-height: 2rem;
        margin: 0 3rem;
    }

    @media (max-width: 320px) {
        font-size: 1.75rem;
        line-height: 2rem;
        margin: 0 2rem;
    }
`;

const TagLine = styled.p`
    color: ${({ theme }) => theme.white};
    font-size: 1rem;
    max-width: 55%;
    text-align: center;
    margin: 0 0 2rem 0;

    @media (max-width: 600px) {
        max-width: 90%;
    }
`;

const ArrowUp = styled.div`
    width: 0;
    height: 0;
    border-left: 0.5rem solid transparent;
    border-right: 0.5rem solid transparent;
    border-bottom: 0.5rem solid ${({ theme }) => theme.white};
    position: absolute;
    bottom: 0;
    left: 50%;
`;

const CustomersContainer = styled.div`
    background-color: ${({ theme }) => theme.white};
    padding: 0 0 2.5rem 0;

    @media (max-width: 600px) {
        padding: 2.5rem 0;
    }
`;

const SubTitle = styled.h2`
    font-family: 'Montserrat', sans-serif;
    font-size: 2.5rem;
    line-height: 3rem;
    font-weight: 300;
    margin-top: 5rem;
    margin-bottom: 2rem;
    text-align: center;
    text-transform: uppercase;

    @media (max-width: 600px) {
        font-size: 1.5rem;
        line-height: 2rem;
        margin-top: 2rem;
    }
`;

const ShowcasesContainer = styled.ul`
    background-color: ${({ theme }) => theme.gray};
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    list-style: none;
`;

const ShowcasesItem = styled.li`
    display: flex;
    padding: 0.5em;
    margin: 0;
    list-style: none;
    box-sizing: border-box;

    @media (min-width: 600px) {
        width: 50%;
    }
`;

const InsideOurLabText = styled.p`
    text-align: center;

    margin: 0;

    @media (max-width: 600px) {
        margin: 0.5rem 1.5rem;
    }
`;

const Anchor = styled.a`
    display: block;
    position: relative;
    top: -100px;
    visibility: hidden;
`;

const prepareRepositories = rawRepositories => {
    return rawRepositories.nodes && rawRepositories.nodes.length >= 1
        ? [
              ...rawRepositories.nodes[0].data.francois.repositories.nodes.map(
                  node => ({
                      name: node.name,
                      url: node.url,
                      stars: node.stargazers.totalCount,
                  }),
              ),
              ...rawRepositories.nodes[0].data.marmelab.repositories.nodes.map(
                  node => ({
                      name: node.name,
                      url: node.url,
                      stars: node.stargazers.totalCount,
                  }),
              ),
          ]
              .sort((project1, project2) => project2.stars - project1.stars)
              .slice(0, 30)
        : [];
};

const Showcases = ({
    customers,
    locale,
    showcases,
    headerImage,
    showcaseImages,
    repositories,
    t,
}) => (
    <Container>
        <Helmet title={t('seo.showcasesTitle')}>
            <html lang={locale} />
            <meta name="description" content={t('seo.showcasesDescription')} />
        </Helmet>
        <TopBar white role="presentation" />
        <header>
            <AppBar />
            <HeaderImage image={headerImage}>
                <MaxWidthContainer>
                    <Intro>
                        <Title>{t('showcase.title')}</Title>
                        <TagLine>{t('showcase.tagline')}</TagLine>
                        <PrimaryLink href="mailto:contact@marmelab.com?subject=Contact from the showcase page">
                            {t('aboutus.contact_us')}
                        </PrimaryLink>
                    </Intro>
                </MaxWidthContainer>
                <div>
                    <ArrowUp />
                </div>
            </HeaderImage>
        </header>
        <div role="main">
            <CustomersContainer>
                <Customers images={customers} />
            </CustomersContainer>
            <MaxWidthContainer>
                <SubTitle>{t('showcase.our_work')}</SubTitle>
                <ShowcasesContainer>
                    {showcases.map(showcase => {
                        const image = showcaseImages.find(image =>
                            image.absolutePath.includes(showcase.image),
                        );
                        return (
                            <ShowcasesItem key={showcase.id}>
                                <BigShowcase
                                    showcase={showcase}
                                    image={image}
                                />
                            </ShowcasesItem>
                        );
                    })}
                </ShowcasesContainer>
                <SubTitle>
                    <Anchor id="open-source" />
                    {t('showcase.inside_our_lab')}
                </SubTitle>
                <InsideOurLabText>
                    {t('showcase.inside_our_lab_text_1')}
                </InsideOurLabText>
                <InsideOurLabText
                    dangerouslySetInnerHTML={{
                        __html: t('showcase.inside_our_lab_text_2'),
                    }}
                />
                <MaxWidthContainer width={1024}>
                    <OpensourceProjects repositories={repositories} />
                </MaxWidthContainer>
                <LazyLoad height={684} once offset={100}>
                    <GithubLink />
                </LazyLoad>
            </MaxWidthContainer>
            <CallToAction />
        </div>
        <Footer />
    </Container>
);

Showcases.propTypes = {
    customers: PropTypes.array,
    locale: PropTypes.string,
    showcases: PropTypes.array.isRequired,
    headerImage: PropTypes.object,
    showcaseImages: PropTypes.array,
    repositories: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            stars: PropTypes.string.isRequired,
            url: PropTypes.string.isRequired,
        }),
    ).isRequired,
    t: PropTypes.func.isRequired,
};

export default compose(
    mapProps(({ data, locale }) => ({
        locale,
        showcases: data.showcases.edges
            .map(({ node }) => ({
                id: node.id,
                ...node.frontmatter,
            }))
            .filter(showcase => showcase.lang === locale),
        showcaseImages: data.showcaseImages.edges.map(({ node }) => node),
        headerImage: data.headerImage,
        customers: data.customers.edges.map(({ node }) => node),
        repositories: prepareRepositories(data.repositories),
    })),
    translate(),
)(Showcases);
