import React from 'react';
import PropTypes from 'react-proptypes';
import styled from 'styled-components';
import {
    FaTwitter as IconTwitter,
    FaGithub as IconGithub,
} from 'react-icons/fa';
import { MdArrowForward as Forward } from 'react-icons/md';
import { translate } from 'react-polyglot';

const StyledIconTwitter = styled(IconTwitter)`
    font-size: 23px;
    margin-right: 5px;
    margin-left: 1rem;
`;

const StyledIconGithub = styled(IconGithub)`
    font-size: 23px;
    margin-right: 5px;
`;

const StyledForward = styled(Forward)`
    color: #1ea1f1;
    font-size: 22px;
    margin-left: 10px;
    margin-right: 1rem;
`;

const IconLinkContainer = styled.a`
    color: #1ea1f1;
    font-size: 13px;
    line-height: 20px;
    font-weight: bold;
    text-transform: uppercase;
    text-decoration: none;
    align-self: center;
    letter-spacing: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1.875rem;
    margin-bottom: 0.625rem;
`;

const getIconLink = (Icon, label, href = 'https://twitter.com/marmelab') => {
    const IconLink = ({ t }) => (
        <IconLinkContainer href={href}>
            <Icon color="#1ea1f1" />
            <span>{t(label)}</span>
            <StyledForward />
        </IconLinkContainer>
    );

    IconLink.propTypes = {
        t: PropTypes.func.isRequired,
    };

    return IconLink;
};

export const TwitterLink = translate()(
    getIconLink(StyledIconTwitter, 'viewUsOnTwitter'),
);
export const GithubLink = translate()(
    getIconLink(
        StyledIconGithub,
        'viewUsOnGithub',
        'https://github.com/marmelab/',
    ),
);
