import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { translate } from 'react-polyglot';

import ViewMore from '../ViewMore';
import WithBackgroundImage from '../Image/WithBackgroundImage';
import defaultTheme from '../theme';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 100%;
    margin: 0;
    padding: 0;
    position: relative;
`;

const InnerContainer = styled.div`
    background-color: ${({ theme }) => theme.white};
    display: flex;
    flex-direction: column;
    padding: 0 2rem 1.5rem 2rem;
    box-shadow: inset 0 -1px 0 0 #d4d4ce;
    flex: 1 0 auto;
`;

const Title = styled.h3`
    color: ${({ theme }) => theme.grayDarker};
    font-family: 'Montserrat', sans-serif;
    font-size: 1.5rem;
    font-weight: normal;
    margin: 3rem 0 0.5rem 0;

    @media (max-width: 600px) {
        font-size: 1.2rem;
        line-height: 1.4rem;
        margin: 1rem 0 0rem 0;
    }
`;

const ProductTitle = styled.h2`
    font-family: 'Montserrat', sans-serif;
    font-size: 1.8rem;
    line-height: 2.2rem;
    font-weight: 300;
    margin: 0.5rem 0 1.5rem 0;
    text-transform: uppercase;

    @media (max-width: 600px) {
        font-size: 1.3rem;
        line-height: 1.5rem;
        margin: 0.5rem 0rem 0.5rem 0rem;
    }

    @media (max-width: 320px) {
        font-size: 1.3rem;
        line-height: 1.5rem;
        margin: 0.5rem 0rem 0.5rem 0rem;
    }
`;

const ProductText = styled.p`
    margin: 1rem 0 2rem 0;

    @media (max-width: 600px) {
        font-size: 0.8rem;
    }
`;

const Buttons = styled.div`
    margin-top: auto;
`;

const CustomerButton = props => (
    <ViewMore {...props} color={defaultTheme.blue} />
);

const ProjectButton = props => (
    <ViewMore {...props} color={defaultTheme.orange} />
);

const Label = styled.div`
    color: ${({ theme }) => theme.white};
    position: absolute;
    top: 0;
    right: 0;
    padding: 1rem 2rem;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.8rem;
    letter-spacing: 2px;
`;

const LabelCustomer = styled(Label)`
    background-color: ${({ theme }) => theme.blue};
`;

const LabelProject = styled(Label)`
    background-color: ${({ theme }) => theme.orange};
`;

const BigShowcase = ({
    showcase: { customer, text, title, project, link },
    image,
    t,
}) => (
    <Container>
        <WithBackgroundImage
            image={image}
            style={{ minHeight: 190, zIndex: 0 }}
        />
        {customer ? (
            customer === 'marmelab' ? (
                <LabelProject>{t('showcases.product')}</LabelProject>
            ) : (
                <LabelCustomer>{t('showcases.project')}</LabelCustomer>
            )
        ) : (
            <LabelProject>{t('showcases.lab_work')}</LabelProject>
        )}
        <InnerContainer>
            <Title>{customer || project}</Title>
            <ProductTitle>{title}</ProductTitle>
            <ProductText>{text}</ProductText>
            <Buttons>
                {customer ? (
                    <CustomerButton
                        href={link}
                        label={t('showcases.view_site')}
                    />
                ) : (
                    <ProjectButton
                        href={link}
                        label={t('showcases.view_project')}
                    />
                )}
            </Buttons>
        </InnerContainer>
    </Container>
);

BigShowcase.propTypes = {
    showcase: PropTypes.shape({
        text: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        customer: PropTypes.string,
        project: PropTypes.string,
        link: PropTypes.string,
    }).isRequired,
    image: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
};

export default translate()(BigShowcase);
