import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FaStar as Star } from 'react-icons/fa';
import Media from 'react-media';

const Container = styled.div`
    margin-top: 1em;
    display: flex;
    flex-wrap: wrap;
`;

const ProjectContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    padding-left: 1rem;
    padding-right: 1rem;

    @media (min-width: 600px) {
        width: 50%;

        &: nth-child(odd) {
            padding-left: 0;
        }

        &: nth-child(even) {
            padding-right: 0;
        }
    }
`;

const Project = styled.div`
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    padding-bottom: 1rem;
    padding-top: 1rem;
    border-bottom: solid 1px ${({ theme }) => theme.grayDark};

    &: nth-child(odd) {
        padding-left: 0;
    }

    &: nth-child(even) {
        padding-right: 0;
    }
`;

const Title = styled.a`
    color: ${({ theme }) => theme.orange};
    text-decoration: none;
`;

const Stars = styled.span`
    color: ${({ theme }) => theme.grayDarker};
    margin-left: auto;
`;

const StarIcon = styled(Star)`
    color: ${({ theme }) => theme.grayDarker};
    margin-left: 1rem;
    flex-grow: 0;
`;

const OpensourceProjects = ({ repositories }) => {
    return (
        <Media defaultMatches={false} query="(max-width: 600px)">
            {matches => (
                <Container>
                    {repositories
                        .slice(0, matches ? 10 : undefined)
                        .map(project => (
                            <ProjectContainer key={project.name}>
                                <Project>
                                    <Title href={project.url}>
                                        {project.name}
                                    </Title>
                                    <Stars>{project.stars}</Stars>
                                    <StarIcon />
                                </Project>
                            </ProjectContainer>
                        ))}
                </Container>
            )}
        </Media>
    );
};

OpensourceProjects.propTypes = {
    repositories: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            stars: PropTypes.string.isRequired,
            url: PropTypes.string.isRequired,
        }),
    ).isRequired,
};

export default OpensourceProjects;
