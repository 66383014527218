import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Media from 'react-media';
import Carousel from 'react-slick';

import MaxWidthContainer from './MaxWidthContainer';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import Image from './Image/Image';

const Container = styled.div`
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
`;

const Customer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 2.5rem 0.5rem 0;
    flex-basis: 10%;

    @media (max-width: 600px) {
        padding: 0;
        margin: 0;
        margin: 0;
    }
`;

const imageStyle = {
    maxWidth: '75px',
    width: '100%',
    margin: 'auto',
};

const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    autoplay: true,
    autoplaySpeed: 2000,
    swipeToSlide: true,
    arrows: false,
};

const Customers = ({ images }) => (
    <MaxWidthContainer>
        <Media defaultMatches={false} query="(max-width: 600px)">
            {matches =>
                matches ? (
                    <Carousel {...settings}>
                        {images.map(image => (
                            <div key={image.name}>
                                <Image
                                    src={image}
                                    style={imageStyle}
                                    role="presentation"
                                />
                            </div>
                        ))}
                    </Carousel>
                ) : (
                    <Container>
                        {images.map(image => (
                            <Customer key={image.name}>
                                <Image
                                    src={image}
                                    style={imageStyle}
                                    role="presentation"
                                />
                            </Customer>
                        ))}
                    </Container>
                )
            }
        </Media>
    </MaxWidthContainer>
);

Customers.propTypes = {
    images: PropTypes.array.isRequired,
};

export default Customers;
